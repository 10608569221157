<template>
  <div>
    <div class="flex justify-items-center" v-if="viewAlert">
      <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4 h-auto">
          <span><p>{{ $t('plan.already_used') }}</p></span>
          <div class="w-full grid justify-items-center ">
            <vs-button
              @click="openPopup"
              type="flat"
              class="float-left mt-1 hover:animate-bounce transform hover:scale-110">
              {{ $t('plan.subscribe_here') }}
            </vs-button>
          </div>
        </vs-alert>
    </div>
    <PlanSubscribePopup
          :visible="showSubscribePopup"
          :usageData="usageData"
          @close="closePopup"/>
  </div>
</template>
<script>

import { isAdmin } from '@/util/Util'

import PlanSubscribePopup from './PlanSubscribePopup.vue'

import PlanBillingService from '@/services/api/PlanBillingService'

export default {
  directives: {

  },
  components: {
    PlanSubscribePopup,
  },
  props: {
    target: {
      default: null,
      type: String
    }
  },
  data: () => ({
    service: null,
    isUsageFull: false,
    showSubscribePopup: false,
    usageData: null
  }),
  computed: {
    viewAlert(){
      return this.isUsageFull
    }
  },
  beforeMount() {
    this.service = PlanBillingService.build(this.$vs)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$vs.loading()

      this.service.getPlanUsageByTarget(this.target).then(
        response => {
          this.populateModels(response)
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(
        () => {
          this.$vs.loading.close()
        }
      )
    },
    populateModels(response){
      this.isUsageFull = _.get(response, this.target+'.is_usage_full')
      this.usageData = response
    },
    openPopup() {
      this.showSubscribePopup = true
    },
    closePopup(show) {
      this.showSubscribePopup = show
    },
    isAdmin() {
      return isAdmin()
    },
  }
}
</script>

<style>

</style>
